import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";


import Experience from "./Experience.jsx";
import "./style.css";
const root = ReactDOM.createRoot(document.querySelector("#root"));

const LazyLoadedComponent = () => {
  useEffect(() => {
    window.initOnFirstLoad();
  }, []);

  return null;
};

function App() {
  const canvasRef = useRef();
  return (
    <Suspense fallback={<LazyLoadedComponent></LazyLoadedComponent>}>
      <Canvas
        ref={canvasRef}
        gl={{ antialias: false, logarithmicDepthBuffer: false }}
        camera={{
          position: [
            -22.573198756174857, 1.2914112358158552, -79.4961716704387,
          ],
          fov: 12,
          near: 0.1,
          far: 20000,
        }}
      >
        <Experience canvasRef={canvasRef} />
      </Canvas>
    </Suspense>
  );
}
root.render(<App></App>);
