import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import { extend, useFrame, useLoader, useThree } from "@react-three/fiber";
import { EffectComposer, LUT, Noise } from "@react-three/postprocessing";
import { LUTCubeLoader } from "postprocessing";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Water } from "three/examples/jsm/objects/Water2";


extend({ Water });
function CameraAnimation() {
    const { camera } = useThree();
    const cameraRef = useRef(camera);
    useEffect(() => {
        const tl = new TimelineLite({ paused: true,  });
        tl.to(cameraRef.current.position, {
            duration: 6, x: -81.2827969790891, y: 4.208202274280242, z:
                14.361626098619665
        }, 0)
            .to(cameraRef.current.position, {
                duration: 6, x:
                    2.0731523471817, y: 4.63637943187539, z:
                    51.87945802255283
            }, 4)
        tl.play();

        return () => tl.kill();
    }, []);

    return null;
}

function Plants() {

    let grass = useGLTF("/scene.glb");

    return <primitive object={grass.scene} ></primitive>;
}

function Ocean() {
    const ref = useRef();
    const waterNormals = useLoader(THREE.TextureLoader, "/waterNormals.jpeg");
    const { mouse, camera } = useThree()
    const vec = new THREE.Vector3()
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
    const geom = useMemo(() => new THREE.PlaneGeometry(400, 400), []);
    //   useFrame((state, delta) => (console.log(camera)))

    const config = useMemo(
        () => ({
            alpha: 0.,
            flowDirection: new THREE.Vector2(0.81, 0.41),
            textureWidth: 1024,
            textureHeight: 1024,
            fog: true,
            waterNormals: waterNormals
        }),
        [waterNormals]
    );
    //   useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta*.03))

    return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />;
}


 function Scene({canvasRef}) {
    const { size } = useThree();
    const controlsRef = useRef();
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [initialTarget, setInitialTarget] = useState(new THREE.Vector3());

    useEffect(() => {
        console.log(canvasRef);

        const handleMouseMove = (event) => {
            if (canvasRef.current) {

            const rect = canvasRef.current.getBoundingClientRect();
            const x = (event.clientX - rect.left) / rect.width;
            const y = (event.clientY - rect.top) / rect.height;
            setMousePosition({ x, y });
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useState(() => {
        setInitialTarget(new THREE.Vector3(0, 3, 1));
    });

    useFrame(() => {
        if (controlsRef.current) {
            const { x, y } = mousePosition;
            const targetX = (x - 0.5) * .4; 
            const targetY = (y - 0.5) * .4; 

            const targetPosition = initialTarget.clone().add(new THREE.Vector3(targetX, targetY, 0));
            controlsRef.current.target.lerp(targetPosition, 0.1); 
        }
    });

    return (
        <>
      <OrbitControls
        ref={controlsRef}
        target={[0, 3, 1]}
        enableDamping={true}
        enableRotate={false}
        enablePan={false}
        enableZoom={false}
      />
        </>
    );
}
export default function Experience({ canvasRef }) {
    const [tex, setTex] = useState(null);

    useEffect(() => {
        async function loadTexture() {
            const texture = await new LUTCubeLoader().load('/Clayton33.CUBE');
            setTex(texture);
        }

        loadTexture();
    }, []);

    return (
        <>
            <CameraAnimation></CameraAnimation>
            <Suspense fallback={null}>
                <Ocean />
            </Suspense>
            <Scene canvasRef={canvasRef}></Scene>
            <Plants />
            <Environment
                files="/kiara_1_dawn_1k (3).hdr"
                background
                blur={0.1}
            ></Environment>
            <EffectComposer multisampling={0} disableNormalPass={true}>
                {/* <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} /> */}

                <LUT lut={tex} ></LUT>
                <Noise opacity={0.125} />
            </EffectComposer>
        </>
    );
}
